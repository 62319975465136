import z from 'zod';

export const ParticipantJobSchema = z.enum([
  'performer',
  'approver',
  'co-approver',
  'watchman',
  'requester',
  'coordinator',
  'inspector',
  'inspected',
  'instructor',
  'instructed',
  'author'
]);
