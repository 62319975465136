import store from '../../store';
import { FormSignerError } from './error';
import {
  patchFormSignerSignature,
  putFormSignerSignature,
  resetFormSignerState,
  setFormSignerState,
  setGroupAuthPhotos,
  setThirdPartySignatures
} from './slice';
import voroQueryClient from '../voroQueryClient';
import {
  CanSignReturn,
  FormSignerSignature,
  FormSignerState,
  SignReturn
} from './types';
import {
  FormSignatureDB,
  Signature
} from '../../../vorotypes/types/formSignature';
import { TemplateConfig } from '../../../vorotypes/types/formTemplate';
import { AppLocalFormAnswer } from '../../../vorotypes/types/formAnswer';
import { Photo } from '../../types/form';

interface UseFormSignerClient {
  canSign: (id_user: number) => Promise<CanSignReturn>;
  get: (id_user: number) => FormSignerSignature | undefined;
  initFormSigner: (formAnswer: AppLocalFormAnswer) => void;
  reset: () => void;
  sign: (
    payload: Omit<Signature, 'date'>,
    templateConfig: TemplateConfig
  ) => SignReturn;
  updateRemote: (formSignatures: FormSignatureDB[]) => number;
}

class FormSignerClient implements UseFormSignerClient {
  constructor() {}
  /**
   * Checks if a user is able to sign
   * @param id the user's id
   * @returns `true` if the user is able to sign
   * @returns `false` if the user is not able to sign
   * @author Douglas Flores
   */
  async canSign(id_user: number): Promise<CanSignReturn> {
    /* Getting Data */
    const { formSignerSignatures, formAnswer } = store.getState().formSigner;
    const sig = formSignerSignatures?.find(fss => fss.id === id_user);
    if (!sig) return { result: false, reason: 'error' };
    const ulid = formAnswer?.ulid ?? formAnswer?.answer?.metadata?.ulid;
    /* Getting Remote Signatures */
    const formSignatures = !!ulid
      ? await voroQueryClient.getQueryData<FormSignatureDB[]>([
          'formSignatures',
          ulid
        ])
      : undefined;
    /* Checking availability */
    const isLeader = sig.isLeader;
    const someDidNotSign = formSignerSignatures
      .filter(signature => !signature?.isLeader)
      .some(signature => !signature.hasSigned);
    const isRemote = formSignatures?.some(fs => fs.id_user === sig.id);
    if (isLeader && someDidNotSign)
      return { result: false, reason: 'leader_sign_last' };
    else if (isRemote) return { result: false, reason: 'remote' };
    return { result: true, reason: 'ok' };
  }
  get(id_user: number) {
    try {
      const formSignerSignatures =
        store.getState().formSigner.formSignerSignatures;
      const target = formSignerSignatures?.find(fss => fss.id === id_user);
      return target;
    } catch (error) {
      if (error instanceof FormSignerError) throw error;
      throw new FormSignerError(
        { message: 'Unexpected error!', code: 900 },
        { cause: error }
      );
    }
  }
  /**
   * Initializes the formSignerSlice to start the signature collection of a formAnswer
   * @param formAnswer the formAnswer that must be signed
   * @returns nothing
   * @author Douglas Flores
   */
  initFormSigner(formAnswer: AppLocalFormAnswer) {
    const currentState = store.getState().formSigner;
    if (
      !!currentState?.formAnswer ||
      !!currentState?.formSignerSignatures.length
    )
      return;

    const localFormSignerSignatures: Array<FormSignerSignature> = [];
    const participants = formAnswer.participants;
    let leaderSignature: FormSignerSignature | undefined;
    participants.forEach(p => {
      const match = localFormSignerSignatures?.find(ps => ps.id === p.id_user);
      if (!match) {
        const signature = {
          id: p.id_user,
          name: p?.name,
          hasSigned: false,
          date: undefined,
          isLeader: p.id_user === formAnswer?.id_postedby,
          signatureImage: undefined,
          photo: undefined,
          personalId: undefined
        };
        if (!signature.isLeader) localFormSignerSignatures.push(signature);
        else leaderSignature = signature;
      }
    });
    const sorted = localFormSignerSignatures.sort((a, b) => {
      if (!a?.name && !b?.name) return 0;
      return (a?.name ?? '') > (b?.name ?? '') ? 1 : -1;
    });
    if (!!leaderSignature) sorted.push(leaderSignature);
    /* Building State */
    const state: FormSignerState = {
      formSignerSignatures: localFormSignerSignatures,
      formAnswer
    };
    /* Setting State */
    store.dispatch(setFormSignerState(state));
  }
  /**
   * Pushes a photo into the groupAuthPhotos array
   * @param photo a photo to be pushed
   * @author Douglas Flores
   */
  pushGroupPhoto(photo: Photo) {
    const groupAuthPhotos = store.getState().formSigner.groupAuthPhotos;
    if (!groupAuthPhotos) store.dispatch(setGroupAuthPhotos([photo]));
    else {
      groupAuthPhotos.push(photo);
      store.dispatch(setGroupAuthPhotos([...groupAuthPhotos]));
    }
  }
  /**
   * Pushes a photo into the thirdPartySignatures array
   * @param photo a photo to be pushed
   * @author Douglas Flores
   */
  pushThirdPartySignature(photo: Photo) {
    const tpSignatures = store.getState().formSigner.thirdPartySignatures;
    if (!tpSignatures) store.dispatch(setGroupAuthPhotos([photo]));
    else {
      tpSignatures.push(photo);
      store.dispatch(setThirdPartySignatures([...tpSignatures]));
    }
  }
  /**
   * Resets/clears the formSigner's state
   */
  reset() {
    store.dispatch(resetFormSignerState());
  }
  /**
   * A user signs a formAnswer
   * @param payload all data needed for a signature, except the date
   * @param templateConfig the configuration object of the formTemplate
   * @returns an object with the newly added FormSignerSignature or an error
   * @author Douglas Flores
   */
  sign(
    payload: Omit<Signature, 'date'>,
    templateConfig: TemplateConfig
  ): SignReturn {
    try {
      /* Getting Data */
      const formSignerSignatures =
        store.getState().formSigner.formSignerSignatures;
      const currentValue = formSignerSignatures.find(
        fss => fss.id === payload.id
      );
      if (!currentValue) {
        throw new FormSignerError({
          message: `FormSignerSignature not found for id-${payload.id}`,
          code: 901
        });
      }

      /* Validating signature accordingly with the template config */
      const requiresSignatureImage = !!templateConfig?.biodynamicSignature;
      const hasSignatureImage = !!payload?.signatureImage?.base64;
      if (requiresSignatureImage && !hasSignatureImage) {
        throw new FormSignerError({
          message: `Missing biodynamic signature for id-${payload.id}!`,
          code: 902
        });
      }

      const requiresPersonalData = !!templateConfig?.requirePersonalDataInput;
      const hasPersonalData = !!payload?.personalId;
      if (requiresPersonalData && !hasPersonalData) {
        throw new FormSignerError({
          message: `Missing personal data input for id-${payload.id}`,
          code: 903
        });
      }

      const requiresPhoto = !!templateConfig?.requireSignaturePhoto;
      const hasSignaturePhoto = !!payload.photo?.base64;
      if (requiresPhoto && !hasSignaturePhoto) {
        throw new FormSignerError({
          message: `Missing signature photo for id-${payload.id}`,
          code: 904
        });
      }

      /* Updating the form signer signature */
      const updatedFormSignerSignature: FormSignerSignature = {
        ...currentValue,
        ...payload,
        hasSigned: true,
        date: new Date().toISOString()
      };
      store.dispatch(putFormSignerSignature(updatedFormSignerSignature));
      return { data: updatedFormSignerSignature };
    } catch (error) {
      if (error instanceof FormSignerError) return { error };
      return {
        error: new FormSignerError(
          { message: 'Unexpected error!', code: 900 },
          { cause: error }
        )
      };
    }
  }
  /**
   * Update formSignerSignatures to set remote signatures has signed
   * @param formSignatures list of remote signatures
   * @returns the quantity of formSignerSignatures updated
   * @author Douglas Flores
   */
  updateRemote(formSignatures: FormSignatureDB[]) {
    let count = 0;
    const formSignerSignatures =
      store.getState().formSigner.formSignerSignatures;
    if (formSignatures.length < 1) return count;
    // Setting remote signatures has signed
    formSignatures.forEach(fs => {
      const signaturePayload: FormSignerSignature = {
        id: fs.id_user,
        hasSigned: true,
        isLeader: false
      };
      // Only sets the signature if the formSignature is from one participant
      if (formSignerSignatures?.some(ps => ps.id === fs.id_user))
        store.dispatch(patchFormSignerSignature(signaturePayload));
      count++;
    });
    return count;
  }
}

const formSignerClient = new FormSignerClient();

export default formSignerClient;
