/* Global imports */
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Skeleton, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
/* Icon imports */
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
/* Local imports */
import authenticatedUseStyles from './styles';
import useStyles from '../../../../useStyles';
import { getCurrentPosition } from '../../../../services/getPos';
import { version } from '../../../../version.json';
import formSignerClient from '../../../../services/formSigner/formSignerClient';
import { CanSignReturn } from '../../../../services/formSigner/types';
import { setGroupAuthPhotos } from '../../../../services/formSigner/slice';
/* Component imports */
import PhotosInput from '../../../../components/PhotosInput';
import FormSignerButton from '../../../../services/formSigner/components/FormSignerButton';
import MainContainer from '../../../../components/MainContainer/MainContainer';
import NavBar from '../../../../components/NavBar';
import { RouterPrompt } from '../../../../components/RouterPrompt';
import SnackbarAlert from '../../../../components/SnackbarAlert';
import SuccessDialog from '../../../../components/SuccessDialog';
/* Hook imports */
import { useEditFormAnswer } from '../../../../hooks/mutation.hooks';
import useAlert from '../../../../hooks/useAlert';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux.hooks';
import {
  useFormAnswer,
  useFormSignatures
} from '../../../../hooks/query.hooks';
/* Type imports */
import { FormAnswerEdit, Photo } from '../../../../types/form';
import { AppLocalFormAnswer } from '../../../../../vorotypes/types/formAnswer';

dayjs.extend(utc);

/* --- Main Function --- */
export default function AuthCompleteForm(props: any) {
  const ulid = props.match.params.ulid;
  /* Styles */
  const theme = useTheme();
  const classes = useStyles(theme);
  const localClasses = authenticatedUseStyles(theme);
  /* Cookies */
  const token = Cookies.get('token');
  /* Hooks */
  const alertHook = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const completeFormAnswer = useEditFormAnswer(ulid);
  const { data: formSignatures } = useFormSignatures(ulid, {
    fetchOnlyOnEmptyCache: true
  });
  const { data: selectedAnswer, isReady } =
    useFormAnswer<AppLocalFormAnswer>(ulid);
  const formAnswerComplete = useAppSelector(
    state => state.completion.fieldAnswers
  );
  const isOnline = useAppSelector(state => state.session.online);
  const groupPhotos = useAppSelector(state => state.formSigner.groupAuthPhotos);
  const formSignerSignatures = useAppSelector(
    state => state.formSigner.formSignerSignatures
  );
  /* States */
  const [thirdPartyPhotos, setThirdPartyPhotos] = useState<Photo[]>();
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  /* Functions */
  function hasThirdPartyUsers() {
    if (!selectedAnswer?.template) return false;
    const fields = selectedAnswer?.template.steps.flatMap(step => step.fields);
    const thirdPartyFields = fields.filter(
      field => field.type === 'ThirdPartyUsers'
    );
    const thirdPartyAnswers = thirdPartyFields.map(
      field => selectedAnswer.answer.items[field.field_id]
    );
    return (
      thirdPartyAnswers.length > 0 &&
      thirdPartyAnswers.some(ans => ans?.length > 0)
    );
  }
  function setGroupPhotos(event: any) {
    dispatch(setGroupAuthPhotos(event));
  }
  function successCheckout() {
    setSuccessDialogOpen(true);
    formSignerClient.reset();
    setTimeout(() => history.push('/home'), 2 * 1000);
  }
  async function checkAvailability(
    id_formSigner: number
  ): Promise<CanSignReturn> {
    try {
      const res = await formSignerClient.canSign(id_formSigner);
      return res;
    } catch (error) {
      console.error(error);
      return { result: true, reason: 'error' };
    }
  }
  async function submit() {
    try {
      setIsSubmitting(true);
      /* Getting current position */
      const pos = await getCurrentPosition();
      /* Building data package */
      const payload = {
        signatures: formSignerSignatures?.filter(
          sig => !formSignatures?.some(fs => fs.id_user === sig.id)
        ),
        authentication:
          !!groupPhotos || !!thirdPartyPhotos
            ? {
                general: {
                  photos: groupPhotos,
                  thirdPartySignatures: thirdPartyPhotos
                }
              }
            : undefined
      };
      if (formAnswerComplete) Object.assign(payload, formAnswerComplete);
      const edit: FormAnswerEdit = {
        route: 'app/formAnswer/complete',
        postData: {
          formAnswerUlid: ulid,
          action: 'complete',
          payload: payload,
          fieldId: 'status', // This must be set only for compatibility reasons with the formEdits that edits form's answer
          date: new Date().toISOString(),
          position: pos || undefined,
          appVersion: version
        }
      };
      /* Executing the mutation */
      completeFormAnswer.mutate(edit, {
        onSuccess: successCheckout,
        onError: err => {
          throw err;
        }
      });
    } catch (error: any) {
      console.error(error?.message);
      alertHook.error(
        'Erro ao completar atividade, tente novamente mais tarde.'
      );
    } finally {
      setIsSubmitting(false);
    }
  }
  function disabledButton() {
    const allSigned = formSignerSignatures?.every(fss => fss.hasSigned);
    const thirdPartySatisfied = hasThirdPartyUsers()
      ? !!thirdPartyPhotos && thirdPartyPhotos.length
      : true;
    const groupAuthSatisfied = selectedAnswer?.template?.config
      ?.requireGroupAuthPhoto
      ? !!groupPhotos?.length
      : true;
    return !allSigned || !thirdPartySatisfied || !groupAuthSatisfied;
  }
  /* Use Effects */
  useEffect(() => {
    if (!!selectedAnswer) formSignerClient.initFormSigner(selectedAnswer);
    if (!!formSignatures) formSignerClient.updateRemote(formSignatures);
  }, [selectedAnswer, formSignatures]);
  /* --- Return --- */
  return (
    <Fragment>
      <NavBar
        isLogged
        headerTitle="Concluir Atividade"
        returnButton
        returnFunction={history.goBack}
      />
      <MainContainer isOnline={isOnline} maxWidth={'xs'} bottomButtons={1}>
        <Typography
          variant="h2"
          mt={1}
          display="flex"
          alignItems="center"
          gap={1}
          fontWeight={900}
        >
          {!!selectedAnswer ? (
            <Fragment>
              <AssignmentTurnedInRoundedIcon style={{ fontSize: 22 }} />
              Concluir
            </Fragment>
          ) : (
            <Skeleton width={260} />
          )}
        </Typography>
        <Typography variant="h4" mt={2} marginBottom={2}>
          {!!selectedAnswer && isReady ? (
            `${selectedAnswer?.answer?.metadata?.title ?? 'DOC'}-${ulid}`
          ) : (
            <Skeleton width={260} />
          )}
        </Typography>
        <Typography marginBlock={2}>
          Revise as informações preenchidas e colete as assinaturas dos
          participantes:
        </Typography>

        <div className={localClasses.buttonList}>
          {!!formSignerSignatures ? (
            formSignerSignatures?.map(fss => (
              <FormSignerButton
                key={fss.id}
                formSignerSignature={fss}
                canSignPromise={checkAvailability(fss.id)}
              />
            ))
          ) : (
            <Skeleton variant="rectangular" width="100%" height={80} />
          )}
        </div>
        {(selectedAnswer?.template?.config?.requireGroupAuthPhoto ||
          selectedAnswer?.template?.config?.optionalGroupAuthPhoto) && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Typography variant="h3" marginTop={6}>
              Fotos dos Envolvidos
            </Typography>
            <PhotosInput
              photos={groupPhotos ?? []}
              onChange={setGroupPhotos}
              height={100}
            />
          </div>
        )}
        {hasThirdPartyUsers() && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Typography variant="h3" marginTop={6}>
              Assinaturas dos Participantes
            </Typography>
            <PhotosInput
              photos={thirdPartyPhotos ?? []}
              onChange={val => setThirdPartyPhotos(val)}
              height={100}
            />
          </div>
        )}
      </MainContainer>

      <div className={classes.footer} style={{ gap: 8 }}>
        <LoadingButton
          id="btn-send-form"
          className={classes.nextButton}
          disabled={disabledButton()}
          variant="contained"
          onClick={submit}
          loading={completeFormAnswer.isMutating || isSubmitting}
        >
          Enviar
        </LoadingButton>
      </div>
      <SuccessDialog open={successDialogOpen} />
      <RouterPrompt
        when={pathname =>
          !pathname.startsWith('/authReview/') &&
          !pathname.startsWith('/authComplete/') &&
          !pathname.startsWith('/ongoing/authComplete/') &&
          !successDialogOpen
        }
        title="Você realmente deseja sair desta página?"
        body={'Se você sair desta página agora, as assinaturas serão perdidas.'}
        onOk={() => formSignerClient.reset()}
      />
      <SnackbarAlert {...alertHook.state} />
    </Fragment>
  );
}
