import store from '../../../store';
import { Complement } from '../../../../vorotypes/types/formTemplate';
import { setFormComplements } from '../slice';

export class FormFilloutComplement {
  constructor() {}
  /**
   * Deletes a complement from formComplements
   * @param id the string id of a complement
   * @returns `0` if there is no complement with the informed id
   * @returns `1` if the complement was removed successfully
   * @author Douglas Flores
   */
  delete(id: string): 0 | 1 {
    const currentComplements = store.getState().fillout.formComplements;
    let tempComplements = { ...currentComplements };
    if (!tempComplements[id]) return 0;
    delete tempComplements[id];
    store.dispatch(setFormComplements(tempComplements));
    return 1;
  }
  /**
   * Gets a complement by the id
   * @param id the string id of a complement
   * @returns `undefined` if the complement is not found
   * @returns the requested complement when it is found
   * @author Douglas Flores
   */
  get(id: string) {
    const complements = store.getState().fillout.formComplements;
    if (!complements) return;
    return complements[id];
  }
  /**
   * Puts a complement into formComplements
   * @description if a complement with the same id exists, it is replaced, otherwise the complement is added
   * @param complement the complement to be putted
   * @author Douglas Flores
   */
  put(complement: Complement) {
    const currentComplements = store.getState().fillout.formComplements;
    let tempComplements = { ...currentComplements };
    tempComplements[complement.id] = complement.value;
    store.dispatch(setFormComplements(tempComplements));
  }
  /**
   * Resets the formComplements list to `undefined`
   * @author Douglas Flores
   */
  reset() {
    store.dispatch(setFormComplements());
  }
}
