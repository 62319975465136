import { FieldAnswerPair } from '../types/form';
import { EditAction, EditLogEntry } from '../../vorotypes/types/formAnswer';
import voroQueryClient from '../services/voroQueryClient';
import { FormField, FormFieldOption } from '../../vorotypes/types/formTemplate';
import dayjs from 'dayjs';

export function extractOtherOptionText(option: string) {
  if (option === '$other$') return '';
  if (typeof option !== 'string') return null;
  //eslint-disable-next-line
  const regex = new RegExp('^[$]other[$]:+');
  return option.replace(regex, '');
}

/**
 * Order a FormFieldOption array
 * @param arr_in input array
 * @returns the input array ordered in alphabetical order
 */
export function orderFormFieldOptions(
  arr_in: Array<FormFieldOption>
): Array<FormFieldOption> {
  if (arr_in?.length > 0) {
    let sortedArray = [...arr_in].sort((a, b) =>
      a.text < b.text ? -1 : a.text > b.text ? 1 : 0
    );
    return sortedArray;
  } else return [];
}

/**
 * Checks if all the required fields have been satisfied
 * @param fields list of form fields
 * @param answers list of field_id-answer tuples
 * @returns true if all required fields have an answer and false otherwise
 */
export function checkRequiredFields(
  fields: Array<FormField>,
  answers: Array<FieldAnswerPair>
) {
  const requiredFields = fields.filter(
    (field: FormField) => field.properties.required === true
  );
  let result = true;
  requiredFields.forEach(field => {
    const answerPayload = answers.find(
      ans => ans?.field_id === field?.field_id
    );
    if (!(!!answerPayload && !!answerPayload?.answer?.length) || !answerPayload)
      result = false;
  });
  return result;
}

export async function updateRecentUsers(payload: number[]) {
  if (!!payload?.length) {
    let updatedRecentUsers: any[] =
      (await voroQueryClient.getQueryData(['coworkers'])) || [];
    payload.forEach(id => {
      if (
        Array.isArray(updatedRecentUsers) &&
        !updatedRecentUsers?.includes(id)
      )
        updatedRecentUsers.push(id);
    });

    if (updatedRecentUsers.length > 5)
      updatedRecentUsers = updatedRecentUsers.slice(
        updatedRecentUsers.length - 5,
        updatedRecentUsers.length
      );
    try {
      await voroQueryClient.setQueryData(['coworkers'], updatedRecentUsers);
    } catch (error) {
      console.warn(error);
    }
  }
}

export function translateEditAction(action: EditAction) {
  switch (action) {
    case 'add':
      return 'adicionar';
    case 'add&cancel':
      return 'adicionar e interromper';
    case 'cancel':
      return 'cancelar';
    case 'change':
      return 'alterar';
    case 'complete':
      return 'completar';
    case 'pause':
      return 'pausar';
    case 'remove':
      return 'remover';
    case 'resume':
      return 'retomar';
    case 'submitToInspection':
      return 'inspecionar';
  }
}

export function getEditDate(
  edits: Array<EditLogEntry>,
  field_id: string,
  answer: any,
  answer_id: number
) {
  /* Getting the editions made to the target field */
  const fieldEdits = edits?.filter(
    edit => edit?.idField === field_id || edit?.fieldId === field_id
  );
  if (!fieldEdits?.length) return answer_id;
  /* Calculating the index of the target edit */
  const targetIdx =
    fieldEdits.length - (Array.isArray(answer) ? answer.length - answer_id : 1);
  if (targetIdx < 0) return answer_id;
  /* Getting the target edit */
  const selectedEdit = fieldEdits[targetIdx];
  if (!selectedEdit?.datetime) return answer_id;
  return dayjs(selectedEdit.datetime).format('DD/MM/YYYY - HH:mm');
}
