import z from 'zod';
import { GeneralFileWrapperSchema, GPSPositionSchema } from './utils.zod';
import { SignatureSchema } from './formSignature.zod';

export const FormAnswerStatusSchema = z.enum([
  'ongoing',
  'cancelled',
  'ok',
  'warning',
  'nonExecution',
  'paused',
  'in_inspection',
  'nonConformity'
]);

export const EditActionSchema = z.enum([
  'add',
  'cancel',
  'change',
  'complete',
  'remove',
  'add&cancel',
  'pause',
  'resume',
  'submitToInspection'
]);

const PauseResumeLogEntry = z.object({
  action: z.enum(['pause', 'resume']),
  datetime: z.string().datetime(),
  position: z.any(),
  metadata: z
    .object({ app_version: z.string().optional() })
    .and(z.record(z.string(), z.any()))
});

export const EditLogEntrySchema = z.object({
  action: EditActionSchema,
  date: z.string().datetime().optional(),
  datetime: z.string().datetime().optional(),
  idField: z.string().optional(),
  fieldId: z.string().optional(),
  payload: z.any().optional()
});

export const AnswerStatusSchema = z.object({
  status: FormAnswerStatusSchema,
  completionData: z.record(z.string(), z.any()).optional(),
  cancelData: z.record(z.string(), z.any()).optional(),
  nonExecutionData: z
    .object({
      answer: z.record(z.string(), z.any())
    })
    .optional(),
  warningText: z.string().optional(),
  pauseResumeHistory: z.array(PauseResumeLogEntry).optional(),
  edits: z.array(EditLogEntrySchema).optional()
});

export const FormAnswerMetadataSchema = z.object({
  uuid: z.string().uuid().optional(),
  ulid: z.string().ulid(),
  timer: z.object({
    answerInitDate: z.string().datetime().optional(),
    answerFinishedDate: z.string().datetime()
  }),
  title: z.string().optional(),
  position: z
    .object({
      answerInitPosition: GPSPositionSchema,
      answerFinishedPosition: GPSPositionSchema
    })
    .optional(),
  app_version: z.string().optional(),
  nonConformingSubmissionAttempts: z.number().optional()
});

export const AuthenticationFormAnswerSchema = z
  .object({
    general: z
      .object({
        photos: z.array(GeneralFileWrapperSchema).optional(),
        thirdPartySignatures: z.array(GeneralFileWrapperSchema).optional()
      })
      .optional()
  })
  .and(z.record(z.string(), z.any()));

export const AnswerItemsSchema = z.record(z.string(), z.any());

export const AnswerSchema = z.object({
  items: AnswerItemsSchema,
  metadata: FormAnswerMetadataSchema,
  signatures: z.array(SignatureSchema).optional(),
  authentication: AuthenticationFormAnswerSchema.optional()
});
