import store from '../../../store';
import db from '../../db';
import { FormFilloutError } from '../error';
import { setFilloutState } from '../slice';
import { FilloutState } from '../types';

export class FormFilloutPersistor {
  constructor() {}

  /**
   * Clears all formFilloutState drafts cache
   * @returns empty object on success
   * @returns an FormFilloutError on error
   * @author Rodrigo Finkler
   */
  async clear(): Promise<ClearReturn> {
    try {
      const allTemplates = await db.table('filloutCache').toArray();
      allTemplates.forEach(async template => {
        await db.filloutCache.delete(template?.formTemplateId);
      });
      return {};
    } catch (error) {
      console.error(error);
      const newError = new FormFilloutError(
        {
          code: 206,
          message: 'Failed to clear cache!'
        },
        { cause: error }
      );
      return { error: newError };
    }
  }
  /**
   * Clears all formFilloutState drafts from templates that are no longer active for the user
   * @param ids_formTemplates the list of ids of active formTemplates for the user
   * @returns empty object on success
   * @returns an FormFilloutError on error
   * @author Rodrigo Finkler
   */
  async clearInactives(ids_formTemplates: number[]): Promise<ClearReturn> {
    try {
      if (!ids_formTemplates.length) return {};
      const allCachedFillouts = await db.table('filloutCache').toArray();
      const filloutsToDelete = allCachedFillouts.filter(
        cachedFillout =>
          !ids_formTemplates.includes(cachedFillout?.formTemplateId)
      );
      if (!filloutsToDelete.length) return {};
      filloutsToDelete.forEach(async fillout => {
        await db.filloutCache.delete(fillout?.formTemplateId);
      });
      return {};
    } catch (error) {
      console.error(error);
      const newError = new FormFilloutError(
        {
          code: 206,
          message: 'Failed to clear inactives from cache!'
        },
        { cause: error }
      );
      return { error: newError };
    }
  }
  /**
   * Deletes a formFilloutState from cache
   * @param id_formTemplate the formTemplate's id of the target filloutForm state
   * @returns empty object on success
   * @returns an FormFilloutError on error
   * @author Douglas Flores
   */
  async delete(id_formTemplate: number): Promise<DeleteReturn> {
    try {
      await db.filloutCache.delete(id_formTemplate);
      return {};
    } catch (error) {
      console.error(error);
      const newError = new FormFilloutError(
        {
          code: 205,
          message: 'Failed to delete fillout state from cache!'
        },
        { cause: error }
      );
      return { error: newError };
    }
  }
  /**
   * Gets all cached filloutStates
   * @returns all cached filloutStates
   * @author Douglas Flores
   */
  async getAll(): Promise<GetListReturn> {
    try {
      const dbres = await db.table('filloutCache').toArray();
      const data = dbres.map(entry => entry?.value as FilloutState);
      return { data };
    } catch (error) {
      console.error(error);
      if (error instanceof FormFilloutError) return { data: [], error };
      const newError = new FormFilloutError(
        {
          code: 203,
          message: 'Failed to get form fillouts from cache!',
          userMessage: 'Falha ao recuperar rascunhos!'
        },
        { cause: error }
      );
      return { data: [], error: newError };
    }
  }
  /**
   * Gets the filout state saved in cache and loads formFillout slice with it
   * @safe this functions catches and handles it's own errors
   * @returns the fillout state retrived from cache
   * @author Douglas Flores
   */
  async restore(): Promise<RestoreReturn> {
    try {
      /* Getting the template id of the current fillout */
      const filloutTemplateId = sessionStorage.getItem('filloutTemplateId');
      const templateId = Number.parseInt(filloutTemplateId ?? '');
      if (Number.isNaN(templateId)) {
        throw new FormFilloutError({
          code: 201,
          message: 'Missing filloutTemplateId from sessionStorage',
          userMessage: 'Nenhum preenchimento em andamento!'
        });
      }
      /* Restoring state from cache */
      const dbres = await db
        .table('filloutCache')
        .where('formTemplateId')
        .equals(templateId)
        .toArray();
      const retrievedState = dbres[0]?.value as FilloutState | null;
      if (!retrievedState) return { data: null };
      store.dispatch(setFilloutState(retrievedState));
      return { data: retrievedState };
    } catch (error) {
      console.error(error);
      if (error instanceof FormFilloutError) return { data: null, error };
      const newError = new FormFilloutError(
        {
          code: 201,
          message: 'Failed to restore fillout state from cache!',
          userMessage: 'Falha ao recuperar rascunho!'
        },
        { cause: error }
      );
      return { data: null, error: newError };
    }
  }
  /**
   * Saves a formFilloutState into cache
   * @safe this functions catches and handles its own errors
   * @returns `0` when there is nothing to save
   * @returns `1` on success
   * @author Douglas Flores
   */
  async save(): Promise<SaveReturn> {
    try {
      const state = store.getState().fillout;
      if (!state?.formTemplate?.id) return { data: 0 };
      await db.table('filloutCache').put({
        formTemplateId: state.formTemplate.id,
        value: state
      });
      return { data: 1 };
    } catch (error) {
      console.error(error);
      const newError = new FormFilloutError(
        {
          code: 202,
          message: 'Failed to save fillout state in cache!',
          userMessage: 'Não foi possível salvar o estado de preenchimento!'
        },
        { cause: error }
      );
      return { error: newError };
    }
  }
}

export interface ClearReturn {
  error?: FormFilloutError;
}

export interface DeleteReturn {
  error?: FormFilloutError;
}

export interface GetListReturn {
  data: FilloutState[];
  error?: FormFilloutError;
}

export interface RestoreReturn {
  data: FilloutState | null;
  error?: FormFilloutError;
}

export interface SaveReturn {
  data?: 0 | 1;
  error?: FormFilloutError;
}
