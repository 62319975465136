/* Global imports */
import { lazy, Suspense, useEffect } from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
/* Page's imports */
const Home = lazy(() => import('./pages/Home'));
const RegisterFA = lazy(() => import('./pages/RegisterFA'));
const History = lazy(() => import('./pages/History'));
const SignIn = lazy(() => import('./pages/SignIn'));
const SingleFA = lazy(() => import('./pages/SingleFA'));
const SingleFALocal = lazy(() => import('./pages/SingleFA/local'));
const SingleFAPublic = lazy(() => import('./pages/SingleFA/public'));
const Equipments = lazy(() => import('./pages/Equipments'));
const ForgotPwd = lazy(() => import('./pages/ForgotPwd'));
const ResetPwd = lazy(() => import('./pages/ResetPwd'));
const SetNewUserPwd = lazy(() => import('./pages/SetNewUserPwd'));
const CancelRecentAnswer = lazy(() => import('./pages/CancelRecentAnswer'));
const SelfRegister = lazy(() => import('./pages/SelfRegister'));
const SelfUpdate = lazy(() => import('./pages/SelfUpdate'));
const Animation = lazy(() =>
  import('./pages/RegisterModuleFlow/components/animation')
);
const AnswerTest = lazy(() => import('./pages/AnswerTest'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const BadHash = lazy(() => import('./pages/RegisterModuleFlow/badHash'));
const MyAccount = lazy(() => import('./pages/MyAccount/index'));
const TutorialVideo = lazy(() => import('./pages/TutorialVideo'));
const TutorialSelect = lazy(() => import('./pages/TutorialSelect'));
const Tutorial = lazy(() => import('./pages/Tutorial'));
const TextEditor = lazy(() => import('./pages/TextEditor'));
const AppSettings = lazy(() => import('./pages/AppSettings'));
const TutorialPermissions = lazy(() => import('./pages/TutorialPermissions'));
const RedirectManager = lazy(() =>
  import('./pages/SelfRegister/redirectManager')
);
const EmailVerification = lazy(() => import('./pages/EmailVerification'));
const OngoingForm = lazy(() => import('./pages/OngoingForm'));
const AddImpeditiveCondition = lazy(() =>
  import('./pages/OngoingForm/AddImpeditiveCondition')
);
const Certifications = lazy(() => import('./pages/Certifications'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const ChangePwd = lazy(() => import('./pages/ChangePwd'));
const Root = lazy(() => import('./pages/Root'));
const QRCode = lazy(() => import('./pages/QRCode'));
const RevalidateFA = lazy(() =>
  import('./pages/OngoingForm/components/RevalidateFA')
);
const FormSelect = lazy(() => import('./pages/FormSelect'));
const FilloutForm = lazy(() => import('./pages/FilloutForm'));
const AddMeasurements = lazy(() =>
  import('./pages/OngoingForm/components/AddMeasurements')
);
const DataReview = lazy(() =>
  import('./pages/FilloutForm/components/DataReview')
);
const CompleteForm = lazy(() =>
  import('./pages/OngoingForm/components/CompleteForm')
);
const AuditFA = lazy(() => import('./pages/AuditFA'));
const Backup = lazy(() => import('./pages/Backup'));
const Inspection = lazy(() => import('./pages/Inspection'));
const MutationDetails = lazy(() => import('./pages/Backup/MutationDetails'));
const DocumentsHistory = lazy(() => import('./pages/DocumentsHistory'));
const Portability = lazy(() => import('./pages/Portability'));
const Audits = lazy(() => import('./pages/OngoingForm/components/Audits'));
const SingleAudit = lazy(() => import('./pages/SingleAudit'));
const SupportCenter = lazy(() => import('./pages/SupportCenter'));
const EmbeddedVideo = lazy(() => import('./pages/EmbeddedVideo'));
const PendingMutations = lazy(() => import('./pages/PendingMutations'));
const AsyncSignatures = lazy(() => import('./pages/AsyncSignature'));
const QrScanner = lazy(() => import('./pages/QrScanner'));
const FormSignatures = lazy(() =>
  import('./pages/OngoingForm/components/FormSignatures')
);
import DiscreteLoading from './pages/DiscreteLoading';
/* Local imports */
import Signatures from './components/Signatures';
/* Local imports */
import { updateDexieFormAnswer, updateRedux } from './services/syncDB';
import { useAppSelector, useAppDispatch } from './hooks/redux.hooks';
import { setHasOfflineFirstIdService } from './slices/sessionSlice';
import { isAuthenticated } from './services/auth';
import NonExecutionTriggered from './pages/NonExecutionTriggered';
import voroQueryClient from './services/voroQueryClient';
import SingleSignOn from './pages/SingleSignOn';
import MicrosoftSSO from './pages/SingleSignOn/microsoft';
import PortabilitySignIn from './pages/PortabilitySignIn';
import FormAnswerMenu from './pages/FormAnswerMenu';
import AuthCompleteForm from './pages/OngoingForm/components/AuthCompleteForm';
import { DqsAttachments } from './pages/OngoingForm/components/DqsAttachments';

/* PrivateRoute component */
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);
/* Main App */
function Routes() {
  const token = Cookies.get('token');
  /* Hooks */
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isOnline = useAppSelector(state => state.session.online);
  const fillout = useAppSelector(state => state.fillout);

  /* ------------------------------------------------------------------------------ */
  /* --- Use Effects                                                                */
  /* ------------------------------------------------------------------------------ */
  useEffect(() => {
    // Running updates when authenticated
    if (isAuthenticated()) {
      updateRedux();
      voroQueryClient.resetInterruptedMutations().finally(() => {
        voroQueryClient.pushMutations();
      });
    }
    // eslint-disable-next-line
  }, []);
  /* Updating formAnswer data on DexieDB if the user is filling a form */
  useEffect(() => {
    updateDexieFormAnswer();
    // eslint-disable-next-line
  }, [fillout, location]);
  /* Triggering mutation push on reconnection */
  useEffect(() => {
    if (isOnline) voroQueryClient.pushMutations();
  }, [isOnline]);
  /* Updating offlinefirst services with token change */
  useEffect(() => {
    if (token) {
      let decoded = jwt_decode(token);
      const { services } = decoded;
      if (services?.includes('OFFLINE_FIRST_ID')) {
        dispatch(setHasOfflineFirstIdService(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  /* --- Return --- */
  return (
    <Suspense fallback={<DiscreteLoading />}>
      <Switch location={location}>
        {/* Private Routes */}
        <PrivateRoute path="/home" exact={true} component={Home} />
        <PrivateRoute path="/new" exact={true} component={RegisterFA} />
        <PrivateRoute path="/formSelect" exact={true} component={FormSelect} />
        <PrivateRoute
          path="/filloutForm/:step"
          exact={true}
          component={FilloutForm}
        />
        <PrivateRoute
          path="/authReview/:id_user"
          exact={true}
          component={DataReview}
        />
        <PrivateRoute
          path="/nonexecTriggered"
          exact={true}
          component={NonExecutionTriggered}
        />
        <PrivateRoute path="/history" exact={true} component={History} />
        <PrivateRoute
          path="/documents-history"
          exact={true}
          component={DocumentsHistory}
        />
        <PrivateRoute path="/my-account" exact={true} component={MyAccount} />
        <PrivateRoute path="/singleFA/:id" exact={true} component={SingleFA} />
        <PrivateRoute
          path="/formAnswer/:ulid"
          exact={true}
          component={FormAnswerMenu}
        />
        <PrivateRoute
          path="/ongoing/:ulid"
          exact={true}
          component={OngoingForm}
        />
        <PrivateRoute
          path="/ongoing/addImpeditiveCondition/:ulid"
          exact={true}
          component={AddImpeditiveCondition}
        />
        <PrivateRoute
          path="/ongoing/addMeasurements/:ulid"
          exact={true}
          component={AddMeasurements}
        />
        <PrivateRoute
          path="/ongoing/revalidate/:ulid"
          exact={true}
          component={RevalidateFA}
        />
        <PrivateRoute
          path="/ongoing/complete/:ulid"
          exact={true}
          component={CompleteForm}
        />
        <PrivateRoute
          path="/ongoing/authComplete/:ulid"
          exact={true}
          component={AuthCompleteForm}
        />
        <PrivateRoute
          path="/inspect/:ulid"
          exact={true}
          component={Inspection}
        />
        <PrivateRoute
          path="/ongoing/audits/:ulid"
          exact={true}
          component={Audits}
        />
        <PrivateRoute
          path="/ongoing/dqsAttachments/:ulid"
          exact
          component={DqsAttachments}
        />
        <PrivateRoute
          path="/ongoing/formSignatures/:ulid"
          exact
          component={FormSignatures}
        />
        <PrivateRoute
          path="/singleFALocal/:ulid"
          exact={true}
          component={SingleFALocal}
        />
        <PrivateRoute path="/equipments" exact={true} component={Equipments} />
        <PrivateRoute
          path="/cancelRecentAnswer/:ulid"
          exact={true}
          component={CancelRecentAnswer}
        />
        <PrivateRoute
          path="/updateUserData/:mode"
          exact={true}
          component={SelfUpdate}
        />
        <PrivateRoute
          path="/answer-test/:id"
          exact={true}
          component={AnswerTest}
        />
        <PrivateRoute
          path="/changePassword"
          exact={true}
          component={ChangePwd}
        />
        <PrivateRoute path="/config" exact={true} component={AppSettings} />
        <PrivateRoute
          path="/certifications"
          exact={true}
          component={Certifications}
        />
        <PrivateRoute
          path="/auditfa/:ulid/:id_company"
          exact={true}
          component={AuditFA}
        />
        <PrivateRoute
          path="/singleAudit/:ulid_formanswer/:id_audit"
          exact={true}
          component={SingleAudit}
        />
        <PrivateRoute path="/backup" exact={true} component={Backup} />
        <PrivateRoute
          path="/backup/detalhes/:idx"
          exact={true}
          component={MutationDetails}
        />
        <PrivateRoute
          exact
          path="/pendingMutations"
          component={PendingMutations}
        />
        <PrivateRoute
          exact
          path="/asyncSignature/:ulid_formAnswer"
          component={AsyncSignatures}
        />
        <PrivateRoute path="/qrScanner" exact component={QrScanner} />
        <PrivateRoute path="/supportCenter" exact component={SupportCenter} />
        <Route path="/" exact={true} component={Root} />
        <Route path="/portabilidade" exact={true} component={Portability} />
        <Route
          path="/portabilidade/:token"
          exact={true}
          component={Portability}
        />
        <Route path="/login" exact={true} component={SignIn} />
        <Route path="/login/:email?" exact={true} component={SignIn} />
        <Route
          path="/login-portabilidade"
          exact={true}
          component={PortabilitySignIn}
        />
        <Route path="/sso" exact={true} component={SingleSignOn} />
        <Route path="/sso/microsoft" exact={true} component={MicrosoftSSO} />
        <Route path="/text-editor" exact={true} component={TextEditor} />
        <Route path="/forgot-password" exact={true} component={ForgotPwd} />
        <Route
          path="/forgot-password/:email"
          exact={true}
          component={ForgotPwd}
        />
        <Route
          path="/reset-password/:token"
          exact={true}
          component={ResetPwd}
        />
        <Route
          path="/set-password/:token"
          exact={true}
          component={SetNewUserPwd}
        />
        <Route
          path="/registro/:register_hash"
          exact={true}
          component={SelfRegister}
        />
        <Route
          path="/registro-gestor/:register_hash"
          exact={true}
          component={RedirectManager}
        />
        <Route
          path="/registro/gestor/:register_hash"
          exact={true}
          component={RedirectManager}
        />
        <Route path="/bad-hash" exact={true} component={BadHash} />
        <Route
          path="/register/:register_hash"
          exact={true}
          component={SelfRegister}
        />
        <Route path="/animation" exact={true} component={Animation} />
        <Route path="/tutorial-video" exact={true} component={TutorialVideo} />
        <Route
          path="/tutorial-select"
          exact={true}
          component={TutorialSelect}
        />
        <Route path="/tutorial" exact={true} component={Tutorial} />
        <Route
          path="/tutorial-permissoes"
          exact={true}
          component={TutorialPermissions}
        />
        <Route
          path="/verify-email/:verification_code"
          exact={true}
          component={EmailVerification}
        />
        <Route
          path="/termos-e-condicoes-de-uso"
          exact={true}
          component={TermsAndConditions}
        />
        <Route
          path="/publicfa/:ulid/:id_company"
          exact={true}
          component={SingleFAPublic}
        />
        <Route path="/qrcode" exact={true} component={QRCode} />
        <Route
          path="/playground/signatures"
          exact={true}
          component={Signatures}
        />
        <Route exact path="/embeddedVideo" component={EmbeddedVideo} />
        {/* 410 Gone - Resource is no longer available (https://developer.mozilla.org/pt-BR/docs/Web/HTTP/Status/410) */}
        <Route path="/410" component={PageNotFound} />
        {/* 404 */}
        <Route path="*" component={PageNotFound} />
        <Route path="*/*" component={PageNotFound} />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}

export default Routes;
