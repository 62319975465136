import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Typography, useTheme } from '@mui/material';
import { FormField } from '../../../../../vorotypes/types/formTemplate';
import useFieldBinaryStyles from './styles';
import ffc from '../../../../services/formFillout/client';

type FieldBinaryProps = {
  field: FormField;
  setAnswers: React.Dispatch<
    React.SetStateAction<{ field_id: string; answer: any }[]>
  >;
  small?: boolean;
};

export default function FieldBinaryQuestion(props: FieldBinaryProps) {
  const { field, setAnswers } = props;
  const theme = useTheme();
  const localClasses = useFieldBinaryStyles(theme);
  /* States */
  const [input, setInput] = useState<'s' | 'n' | undefined>(
    ffc.fieldAnswer.get(field.field_id) || undefined
  );
  /* Functions */
  const handleButtonClick = (answer: 's' | 'n') => setInput(answer);
  const updateAnswer = () =>
    setAnswers((prev: any) => {
      const payload = { field_id: field?.field_id, answer: input };
      if (!prev) return [payload];
      const prevAnswer = prev?.findIndex(
        (ans: any) => ans.field_id === field.field_id
      );
      if (prevAnswer > -1) {
        prev[prevAnswer] = payload;
        return [...prev];
      } else {
        return [...prev, payload];
      }
    });
  /* useEffects */
  useEffect(() => {
    updateAnswer();
    //eslint-disable-next-line
  }, [input]);
  /* --- Return --- */
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <Typography
        variant={props?.small ? 'body1' : 'h3'}
        marginTop={1}
        textAlign="left"
        fontWeight={props?.small ? 500 : 600}
      >
        {field?.properties?.question}
      </Typography>
      {!!field.properties?.description &&
        field.properties?.description !== field.properties?.question && (
          <Typography variant="body2">
            {field.properties?.description}
          </Typography>
        )}
      <div style={{ display: 'flex' }}>
        <Button
          id="btn-yes"
          variant={input === 's' ? 'contained' : 'outlined'}
          color="secondary"
          fullWidth
          onClick={() => handleButtonClick('s')}
          data-cy={input === 's' ? 'yes' : 'none'}
          data-testid={`${field.field_id}-btn-yes`}
          className={
            input === 's'
              ? localClasses.selectedOption
              : localClasses.notSelectedOption
          }
          style={{ borderRadius: '5px 0px 0px 5px' }}
        >
          Sim
        </Button>
        <Button
          id="btn-no"
          variant={input === 'n' ? 'contained' : 'outlined'}
          color="secondary"
          fullWidth
          onClick={() => handleButtonClick('n')}
          data-cy={input === 'n' ? 'no' : 'none'}
          data-testid={`${field.field_id}-btn-no`}
          className={
            input === 'n'
              ? localClasses.selectedOption
              : localClasses.notSelectedOption
          }
          style={{ borderRadius: '0px 5px 5px 0px', borderLeft: 0 }}
        >
          Não
        </Button>
      </div>
    </div>
  );
}
