import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormSignerSignature, FormSignerState } from './types';
import { Photo } from '../../types/form';

const initialFormSignerState = {
  formAnswer: undefined,
  formSignerSignatures: []
} as FormSignerState;

const formSignerSlice = createSlice({
  name: 'formSigner',
  initialState: initialFormSignerState,
  reducers: {
    patchFormSignerSignature: (
      state,
      action: PayloadAction<FormSignerSignature>
    ) => {
      const payload = action.payload;
      const mappedFss =
        state.formSignerSignatures.map(fss => {
          if (fss.id !== payload.id) return fss;
          return { ...fss, ...payload };
        }) ?? [];
      const updatedFormSignerSignatures = [...mappedFss];
      state.formSignerSignatures = updatedFormSignerSignatures;
    },
    putFormSignerSignature: (
      state,
      action: PayloadAction<FormSignerSignature>
    ) => {
      const payload = action.payload;
      let inserted = false;
      const mappedFss =
        state.formSignerSignatures.map(fss => {
          if (fss.id !== payload.id) return fss;
          inserted = true;
          return payload;
        }) ?? [];
      const updatedFormSignerSignatures = [...mappedFss];
      if (!inserted) updatedFormSignerSignatures.push(payload);
      state.formSignerSignatures = updatedFormSignerSignatures;
    },
    setFormSignerState: (state, action: PayloadAction<FormSignerState>) => {
      // In order to trigger the hooks on change, each attribute must be set individually
      state.formAnswer = action.payload?.formAnswer;
      state.formSignerSignatures = action.payload.formSignerSignatures;
    },
    setGroupAuthPhotos: (state, action: PayloadAction<Photo[]>) => {
      state.groupAuthPhotos = action.payload;
    },
    setThirdPartySignatures: (state, action: PayloadAction<Photo[]>) => {
      state.thirdPartySignatures = action.payload;
    },
    resetFormSignerState: state => {
      state.formAnswer = initialFormSignerState.formAnswer;
      state.formSignerSignatures = initialFormSignerState.formSignerSignatures;
    },
    resetFormSignerList: state => {
      state.formSignerSignatures = initialFormSignerState.formSignerSignatures;
    }
  }
});

export const {
  patchFormSignerSignature,
  putFormSignerSignature,
  setFormSignerState,
  setGroupAuthPhotos,
  setThirdPartySignatures,
  resetFormSignerState,
  resetFormSignerList
} = formSignerSlice.actions;
export default formSignerSlice.reducer;
