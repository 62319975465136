import { RequiredCertification } from '../../../../vorotypes/types/formTemplate';
import store from '../../../store';
import { setRequiredCertifications } from '../slice';

export class FormFilloutCertificationRequirement {
  constructor() {}
  compute() {
    /* Getting data */
    const { formTemplate, fieldAnswers } = store.getState().fillout;
    const template = formTemplate?.template;
    /* Without template, there is no required certifications */
    if (!formTemplate) {
      store.dispatch(setRequiredCertifications([]));
      return;
    }
    /* Getting the Restrictions */
    // get all checkbox fields from template
    const allCheckBoxFields =
      template?.steps
        ?.flatMap(step => step.fields)
        ?.filter(f => !!f && f.type === 'CheckBox') ?? [];
    // all marked options
    const allMarkedOptions =
      allCheckBoxFields
        ?.flatMap(field => {
          return field.properties.options?.filter(
            op => fieldAnswers[field.field_id]?.includes(op.option_id)
          );
        })
        ?.filter(op => !!op) ?? [];
    // all marked options with restrictions
    const allRestrictedOptions = allMarkedOptions.filter(
      op => !!op?.required_certifications?.length
    );
    // all not overwritten required_certifications
    const notOverwritten = allRestrictedOptions.flatMap(op => {
      const overwritables = op?.required_certifications?.filter(
        rc => !!rc?.overwritten_by
      );
      if (!overwritables?.length) {
        return op?.required_certifications
          ? [...op?.required_certifications]
          : [undefined];
      } else {
        let options_ids = allRestrictedOptions.map(aro => aro?.option_id);
        return op?.required_certifications?.filter(
          rc => !options_ids.includes(rc?.overwritten_by ?? '')
        );
      }
    });
    const restrictions: RequiredCertification[] = notOverwritten.filter(
      r => !!r
    ) as RequiredCertification[];
    /* Setting the restrictions */
    store.dispatch(setRequiredCertifications(restrictions));
  }
  /**
   * Deletes a requiredCertification from formFillout's slice
   * @param id the certification's id
   * @returns `0` if the requested certification is not required
   * @returns `1` if certification requirement was removed successfully
   * @author Douglas Flores
   */
  delete(id: number): 0 | 1 {
    const currentRequiredCertifications =
      store.getState().fillout.requiredCertifications;
    const filtered = currentRequiredCertifications?.filter(
      rc => rc.certification_id !== id
    );
    if (currentRequiredCertifications.length === filtered.length) return 0;
    store.dispatch(setRequiredCertifications(filtered));
    return 1;
  }
  /**
   * Gets all requiredCertifications
   * @returns the requiredCertifications array from formFillout's slice
   * @author Douglas Flores
   */
  getAll(): RequiredCertification[] | undefined {
    return store.getState().fillout.requiredCertifications;
  }
  /**
   * Puts a certification requirement into requiredCertifications
   * @description if a requiredCertification with the same id exists, it is replaced, otherwise the roadBlocker is added
   * @param requiredCertification the requiredCertification to be putted
   * @author Douglas Flores
   */
  put(requiredCertification: RequiredCertification): void {
    const currentRequiredCertifications =
      store.getState().fillout.requiredCertifications;
    const requiredCertifications = currentRequiredCertifications.filter(
      rc => rc.certification_id !== requiredCertification.certification_id
    );
    requiredCertifications.push(requiredCertification);
    store.dispatch(setRequiredCertifications(requiredCertifications));
  }
  /**
   * Resets the roadBlockers list to `undefined`
   * @author Douglas Flores
   */
  reset(): void {
    store.dispatch(setRequiredCertifications([]));
  }
}
