import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilloutState } from './types';
import {
  FormTemplate,
  RequiredCertification
} from '../../../vorotypes/types/formTemplate';
import { AnswerItems } from '../../../vorotypes/types/formAnswer';
import { RoadBlocker } from './classes/roadBlocker.types';

const initialFilloutState: FilloutState = {
  stepIdx: 0,
  fieldAnswers: {},
  randomSeed: 42,
  requiredCertifications: []
};

export const filloutSlice = createSlice({
  name: 'fillout',
  initialState: initialFilloutState,
  reducers: {
    setFilloutState: (state, action: PayloadAction<FilloutState>) =>
      action.payload,
    resetFilloutState: () => initialFilloutState,
    setFieldAnswers: (state, action: PayloadAction<AnswerItems>) => {
      state.fieldAnswers = action.payload;
    },
    setFormTemplate: (state, action: PayloadAction<FormTemplate>) => {
      state.formTemplate = action.payload;
    },
    setStepIdx: (state, action: PayloadAction<number>) => {
      state.stepIdx = action.payload;
    },
    setFormComplements: (
      state,
      action: PayloadAction<{ [key: string]: any } | undefined>
    ) => {
      state.formComplements = action.payload;
    },
    setRequiredCertifications: (
      state,
      action: PayloadAction<RequiredCertification[]>
    ) => {
      state.requiredCertifications = action.payload;
    },
    setRoadBlockers(state, action: PayloadAction<RoadBlocker[] | undefined>) {
      state.roadBlockers = action.payload;
    }
  }
});

export const {
  setFilloutState,
  resetFilloutState,
  setFormComplements,
  setFormTemplate,
  setStepIdx,
  setFieldAnswers,
  setRequiredCertifications,
  setRoadBlockers
} = filloutSlice.actions;

export default filloutSlice.reducer;
