import { z } from 'zod';
import { GeneralFileWrapperSchema } from './utils.zod';
import { ParticipantJobSchema } from './participant.zod';

export const PersonalIdSchema = z.object({
  type: z.enum(['id', 'cpf']),
  value: z.string()
});

export const SignatureSchema = z.object({
  id: z.number().int(),
  date: z.string().datetime(),
  personalId: PersonalIdSchema.nullish(),
  photo: GeneralFileWrapperSchema.nullish(),
  signatureImage: GeneralFileWrapperSchema.nullish(),
  job: ParticipantJobSchema.optional()
});

export const SignFormAnswerSchema = z.object({
  ulid_formAnswer: z.string().ulid(),
  signature: SignatureSchema
});
