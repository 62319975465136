import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
/* Icon imports */
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import TapAndPlayRoundedIcon from '@mui/icons-material/TapAndPlayRounded';
/* Local imports */
import { CanSignReturn, FormSignerSignature } from '../../types';
import formSignerButtonUseStyles from './styles';

interface FormSignerButtonProps {
  formSignerSignature: FormSignerSignature;
  canSignPromise: Promise<CanSignReturn>;
}

export default function FormSignerButton(props: FormSignerButtonProps) {
  /* Props */
  const { formSignerSignature, canSignPromise } = props;
  /* Styles */
  const theme = useTheme();
  const localClasses = formSignerButtonUseStyles(theme);
  /* States */
  const [canSign, setCanSign] = useState<CanSignReturn>({
    result: false,
    reason: 'error'
  });
  /* Hooks */
  const history = useHistory();
  const [hasSigned, isLeader] = useMemo(
    () => [formSignerSignature?.hasSigned, formSignerSignature?.isLeader],
    [formSignerSignature]
  );
  const descriptionText = useMemo(() => {
    if (hasSigned) return 'Assinado';
    if (canSign.reason === 'leader_sign_last')
      return 'Criador do documento assina por último';
    return 'Não assinado';
  }, [hasSigned, isLeader, canSign]);
  /* Functions */
  function onBtnClick(fssId: number) {
    history.push(`/authReview/${fssId}`);
  }
  /* Effects */
  useEffect(() => {
    canSignPromise.then(res => {
      setCanSign(res);
    });
  }, [canSignPromise]);
  /* Return Component */
  return (
    <Button
      id={`btn-fss-${formSignerSignature.id}`}
      key={formSignerSignature.id}
      variant="contained"
      color={hasSigned ? 'success' : 'secondary'}
      onClick={() => onBtnClick(formSignerSignature.id)}
      disabled={!canSign.result}
      className={clsx(
        localClasses.baseSignerButton,
        hasSigned && localClasses.successBtn
      )}
    >
      <div className={localClasses.sigBtnMainDiv}>
        <Typography fontSize={16} fontWeight={600}>
          {formSignerSignature?.name}
        </Typography>

        <Typography
          variant="body2"
          fontWeight={500}
          display="flex"
          alignItems="center"
          gap={1}
        >
          {canSign.reason === 'remote' && (
            <TapAndPlayRoundedIcon fontSize="small" />
          )}
          {descriptionText}
        </Typography>
      </div>

      <div style={{ alignSelf: 'center', height: 26 }}>
        {hasSigned ? (
          <CheckCircleIcon style={{ fontSize: 26 }} />
        ) : canSign.reason === 'leader_sign_last' ? (
          <ErrorIcon style={{ fontSize: 26 }} />
        ) : (
          <ArrowForwardIcon style={{ fontSize: 26 }} />
        )}
      </div>
    </Button>
  );
}
