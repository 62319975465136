import { AnswerItems } from '../../../../vorotypes/types/formAnswer';
import store from '../../../store';
import { setFieldAnswers } from '../slice';

export class FormFilloutFieldAnswer {
  constructor() {}
  /**
   * Deletes a field's answer from formFillout's slice
   * @param field_id the target field's id
   * @returns `0` if there is no answer to the requested field
   * @returns `1` if the answer was removed successfully
   * @author Douglas Flores
   */
  delete(field_id: string): 0 | 1 {
    const currentFieldAnswers = this.getAll();
    let tempFieldAnswers = { ...currentFieldAnswers };
    if (!tempFieldAnswers[field_id]) return 0;
    delete tempFieldAnswers[field_id];
    store.dispatch(setFieldAnswers(tempFieldAnswers));
    return 1;
  }
  /**
   * Gets a field's answer by the field's id
   * @param field_id the target field's id
   * @returns `undefined` if the field's answer is not found
   * @returns the requested answer when it is found
   * @author Douglas Flores
   */
  get(field_id: string): any {
    const fieldAnswers = this.getAll();
    if (!fieldAnswers) return;
    return fieldAnswers[field_id];
  }
  /**
   * Gets all field answers
   * @returns the `fieldAnswers` object from formFillout's slice
   * @author Douglas Flores
   */
  getAll(): AnswerItems | undefined {
    return store.getState().fillout.fieldAnswers;
  }
  /**
   * Puts a field answer into fieldAnswers
   * @description if there is an answer to the target field, it is replaced, otherwise the answer is added
   * @param fieldAnswer an object with the field's id and the respective answer
   * @author Douglas Flores
   */
  put(fieldAnswer: FieldAnswerPayload): void {
    const currentFieldAnswers = this.getAll();
    let tempFieldAnswers = { ...currentFieldAnswers };
    tempFieldAnswers[fieldAnswer.field_id] = fieldAnswer.answer;
    store.dispatch(setFieldAnswers(tempFieldAnswers));
  }
  /**
   * Puts a field answer into fieldAnswers AND filters the current fieldAnswers
   * @param fieldAnswer the field's answer with the impeditive conditions
   * @param allowedFields a list of field_ids that should compose the final formAnswer along with the impeditive conditions
   * @deprecated study alternatives to avoid using this function
   * @author Douglas Flores
   */
  putImpeditiveConditions(
    fieldAnswer: FieldAnswerPayload,
    allowedFields?: string[]
  ): void {
    const fieldAnswers = this.getAll();
    if (!fieldAnswers) return;
    let tempAnswerItems = { ...fieldAnswers };
    tempAnswerItems[fieldAnswer.field_id] = fieldAnswer.answer;
    if (!!allowedFields) {
      let auxForm: any = {};
      allowedFields.forEach(
        field_id => (auxForm[field_id] = tempAnswerItems[field_id])
      );
      tempAnswerItems = auxForm;
    }
    store.dispatch(setFieldAnswers(tempAnswerItems));
  }
  /**
   * Resets the field answers to an empty object
   * @author Douglas Flores
   */
  reset(): void {
    store.dispatch(setFieldAnswers({}));
  }
}

interface FieldAnswerPayload {
  field_id: string;
  answer: any;
}
