/* Global imports */
import { ulid as genUlid } from 'ulid';
import Cookies from 'js-cookie';
/* Local imports */
import store from '../../store';
import { getCurrentPosition } from '../getPos';
import api from '../api';
import { resetFilloutState, setFilloutState } from './slice';
import voroQueryClient from '../voroQueryClient';
import { FormFilloutCertificationRequirement } from './classes/certificationRequirement';
import { FormFilloutComplement } from './classes/complement';
import { FormFilloutRoadBlocker } from './classes/roadBlocker';
import { FormFilloutFieldAnswer } from './classes/fieldAnswer';
import { FormFilloutPersistor } from './classes/persistor';
import { version } from '../../version.json';
/* Type imports */
import { FormFilloutError } from './error';
import {
  FilloutState,
  FormFilloutErrorCode,
  GetParticipantsReturn,
  UseFormFilloutClient
} from './types';
import { CoworkerListRes } from '../../../vorotypes/types/routes.app.responses';
import { FormTemplate } from '../../../vorotypes/types/formTemplate';
import { Participant } from '../../../vorotypes/types/participant';
import {
  AppLocalFormAnswer,
  AuthenticationFormAnswer,
  FAMutationSubmitPackage,
  FormAnswerStatus
} from '../../../vorotypes/types/formAnswer';
import { FormAnswerMetadataSchema } from '../../../vorotypes/schemas/formAnswer.zod';
import { Signature } from '../../../vorotypes/types/formSignature';
import { logDiscord } from '../logAppEvent';

class FormFilloutClient implements UseFormFilloutClient {
  certificationRequirement: FormFilloutCertificationRequirement;
  complement: FormFilloutComplement;
  fieldAnswer: FormFilloutFieldAnswer;
  persistor: FormFilloutPersistor;
  roadBlocker: FormFilloutRoadBlocker;
  constructor() {
    this.certificationRequirement = new FormFilloutCertificationRequirement();
    this.complement = new FormFilloutComplement();
    this.fieldAnswer = new FormFilloutFieldAnswer();
    this.persistor = new FormFilloutPersistor();
    this.roadBlocker = new FormFilloutRoadBlocker();
  }
  /**
   * Builds a form answer submit package from the current form fillout
   * @returns a FormAnswerSubmitPackage
   * @author Douglas Flores
   */
  async buildMutationPackage(
    signatures?: Signature[],
    authentication?: AuthenticationFormAnswer
  ): Promise<FAMutationSubmitPackage> {
    /* Getting Data */
    const { ulid, fieldAnswers, formTemplate, initDatetime, initPosition } =
      store.getState().fillout;
    const misc = formTemplate?.misc;
    const token = Cookies.get('token');
    const creatorId = token && (JSON.parse(atob(token.split('.')[1]))?.id ?? 0);
    /* Validating */
    if (!ulid) {
      throw new FormFilloutError({
        code: 404,
        message: 'Ulid undefined in buildMutationPackage!',
        userMessage: 'Identificador inválido'
      });
    }
    if (!formTemplate) {
      throw new FormFilloutError({
        code: 120,
        message: 'Template not found in buildMutationPackage!',
        userMessage: 'Formulário não encontrado!'
      });
    }
    /* Building data package */
    const submissionDate = new Date().toISOString();
    const position = await getCurrentPosition();
    const metadata = {
      ulid,
      title: misc?.title ?? 'DOC',
      app_version: version,
      timer: {
        answerInitDate: initDatetime,
        answerFinishedDate: submissionDate
      },
      position: {
        answerInitPosition: initPosition,
        answerFinishedPosition: position
      }
    };
    const { data: parsedMetadata, error } =
      FormAnswerMetadataSchema.safeParse(metadata);
    // The "|| !parsedMetadata" condition is just for TS to be sure that parsedMetadata is not undefined
    if (error || !parsedMetadata) {
      const errorParams = {
        code: 121 as FormFilloutErrorCode,
        message: 'Invalid metadata in buildMutationPackage',
        userMessage: 'Dados faltantes'
      };
      throw new FormFilloutError(errorParams, { cause: error });
    }
    const result: FAMutationSubmitPackage = {
      ulid,
      id_postedby: creatorId,
      answer: {
        items: { ...fieldAnswers },
        metadata: parsedMetadata,
        signatures,
        authentication
      },
      id_formTemplate: formTemplate.id,
      id_formType: formTemplate.id_formType,
      template: formTemplate.template
    };
    return result;
  }
  /**
   * Uses the current fillout state to create an optimistic AppLocalFormAnswer
   * @returns an AppLocalFormAnswer
   * @author Douglas Flores
   */
  async buildOptimisticFormAnswer(): Promise<AppLocalFormAnswer> {
    const mutationPkg = await this.buildMutationPackage();
    const { data: participants } = await this.getParticipants();
    const status = Object.keys(mutationPkg.answer.items).includes(
      'NonExecution'
    )
      ? 'nonExecution'
      : mutationPkg.template?.config?.persistent
      ? 'ongoing'
      : 'ok';
    const answerStatus = {
      status: status as FormAnswerStatus
    };
    const optimisticFormAnswer: AppLocalFormAnswer = {
      ulid: mutationPkg.ulid,
      answer: mutationPkg.answer,
      id_postedby: mutationPkg.id_postedby,
      filledDate: mutationPkg.answer.metadata.timer.answerFinishedDate,
      participants,
      template: mutationPkg.template,
      edits: null,
      onlyLocallyAvailable: true,
      answerStatus: answerStatus,
      idFormType: mutationPkg.id_formType
    };

    return optimisticFormAnswer;
  }
  /**
   * Gets the current fillout state
   * @returns the current fillout state
   * @author Douglas Flores
   */
  get() {
    const state = store.getState().fillout;
    return state;
  }
  /**
   * Initializes the fillout redux to start the fillout of a formTemplate
   * @param formTemplate the formTemplate wich will be filled
   * @returns the full filloutState
   * @author Douglas Flores
   */
  async initFormFillout(formTemplate: FormTemplate, overwrite?: boolean) {
    sessionStorage.setItem('filloutTemplateId', `${formTemplate.id}`);
    if (!overwrite) {
      const { data: restored } = await this.persistor.restore();
      // Do not overwrite current fillout
      if (!!restored) {
        const { ulid, initPosition, initDatetime } = restored;
        if (!!ulid && !!initPosition && !!initDatetime) return restored;
      }
    }
    logDiscord('Novo documento iniciado.');
    // Getting init datetime
    const time_now = Date.now();
    const datetime_now = new Date(time_now).toISOString();
    // Getting current position
    const position = await getCurrentPosition();
    /* Building State */
    const state: FilloutState = {
      stepIdx: 0,
      fieldAnswers: {},
      formTemplate,
      requiredCertifications: [],
      ulid: genUlid(),
      initPosition: position,
      initDatetime: datetime_now,
      randomSeed: time_now
    };
    /* Setting State */
    store.dispatch(setFilloutState(state));
    /* Logging Start */
    try {
      api.post('/app/alert/managed/documentStart', {
        datetime: datetime_now,
        position
      });
    } catch (error) {
      console.warn(error);
    }
    /* Returning Result */
    return state;
  }
  /**
   * Gets a list of all participants of this fillout form
   * @returns a list of participants from the target answer
   * @author Douglas Flores
   */
  async getParticipants(): Promise<GetParticipantsReturn> {
    try {
      /* Getting required data */
      const queryData = await voroQueryClient.getQueryData<CoworkerListRes>([
        'coworkers'
      ]);
      const coworkers = queryData?.coworkers;
      if (!coworkers) {
        throw new FormFilloutError({
          code: 404,
          message: 'Undefined list of coworkers',
          userMessage: 'Usuários participantes não encontrados!'
        });
      }
      const { fieldAnswers, formTemplate } = store.getState().fillout;
      if (!formTemplate) {
        throw new FormFilloutError({
          code: 404,
          message: 'Undefined formTemplate',
          userMessage: 'Formulário não encontrado!'
        });
      }
      const template = formTemplate.template;

      /* Getting user fields */
      const all_fields = template.steps.flatMap(step => step.fields);
      const user_fields = all_fields.filter(
        field =>
          ['User', 'Users'].includes(field?.type ?? '') ||
          field.field_id === 'performers'
      );
      const users: Array<Participant | undefined> = user_fields.flatMap(
        field => {
          const field_answer = fieldAnswers[field.field_id];
          if (Array.isArray(field_answer)) {
            return field_answer.map(id => {
              const coworker = coworkers?.find(c => c.id === id);
              return {
                id_user: id,
                name: coworker?.name ?? null,
                job: field.properties?.job
              };
            });
          } else if (typeof field_answer === 'number') {
            const coworker = coworkers?.find(c => c.id === field_answer);
            return [
              {
                id_user: field_answer,
                name: coworker?.name ?? null,
                job: field.properties?.job
              }
            ];
          } else return undefined;
        }
      );
      const data = (users?.filter(u => !!u) as Participant[]) ?? []; // Just adding typecast because typescript is not smart enough to filter the undefined out
      return { data };
    } catch (error) {
      console.error(error);
      if (error instanceof FormFilloutError) return { data: [], error };
      const newError = new FormFilloutError(
        {
          code: 100,
          message: 'Failed to get participants from a form fillout'
        },
        { cause: error }
      );
      return { data: [], error: newError };
    }
  }
  /**
   * Gets the next step to be filled
   * @returns the next step of a formTemplate to be filled
   * @author Douglas Flores
   */
  nextStep() {
    /* Getting current state data */
    const {
      stepIdx: currentStepIdx,
      formTemplate,
      fieldAnswers
    } = store.getState().fillout;
    const template = formTemplate?.template;
    try {
      if (!template) {
        throw new FormFilloutError({
          code: 120,
          message: 'Template not found',
          userMessage: 'Não foi possível determinar o próximo passo!'
        });
      }
      if (currentStepIdx >= template.steps.length) return currentStepIdx;
      let found: boolean = false;
      let ended: boolean = false;

      let i = currentStepIdx;
      while (!found && !ended) {
        i++;
        if (i >= template.steps?.length) ended = true;
        let filteredFields = template.steps[i]?.fields?.filter(field => {
          // if there is no conditions to be met, the field must appear
          if (!field?.conditions) return true;
          // assuming that none conditions are met
          let oneConditionIsMet = false;
          // checking each condition
          field.conditions?.every(condition => {
            // getting the answer to the linked field
            const currentAnswer = fieldAnswers[condition.field_id];
            // if is array, checks if the current answer includes at least one of the expected answers
            if (
              Array.isArray(currentAnswer) &&
              currentAnswer.find(ans => condition.expected_answer.includes(ans))
            ) {
              oneConditionIsMet = true;
              return false;
            }
            // if it is not an array, it is a simple expected answer
            else if (
              !Array.isArray(currentAnswer) &&
              currentAnswer === condition.expected_answer
            ) {
              oneConditionIsMet = true;
              return false;
            } else return true;
          });
          return oneConditionIsMet;
        });

        if (filteredFields?.length > 0) found = true;
      }

      return i;
    } catch (error) {
      console.error(error);
      return currentStepIdx + 1;
    }
  }
  /**
   * Resets formFillout's state and removes it from cache
   * @author Douglas Flores
   */
  async purgeCurrentState() {
    try {
      const currentState = this.get();
      sessionStorage.removeItem('filloutTemplateId');
      if (!!currentState.formTemplate?.id)
        this.persistor.delete(currentState.formTemplate?.id);
      this.reset();
      return {};
    } catch (error) {
      console.error(error);
      if (error instanceof FormFilloutError) return { error };
      const newError = new FormFilloutError(
        {
          code: 100,
          message: 'Failed to purge current formFillout state'
        },
        { cause: error }
      );
      return { error: newError };
    }
  }
  /**
   * Resets/clears the formFillout's state
   * @author Douglas Flores
   */
  reset() {
    store.dispatch(resetFilloutState());
  }
}

const formFilloutClient = new FormFilloutClient();

export default formFilloutClient;
