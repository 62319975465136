import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CompletionState {
  fieldAnswers: { [key: string]: any };
}

type FieldAnswerPayload = {
  field_id: string;
  answer: any;
};

const initialCompletion: CompletionState = {
  fieldAnswers: {}
};

export const completionSlice = createSlice({
  name: 'completion',
  initialState: initialCompletion,
  reducers: {
    putAnswer: (state, action: PayloadAction<FieldAnswerPayload>) => {
      state.fieldAnswers[action.payload.field_id] = action.payload.answer;
    },
    resetCompletion: state => {
      state = initialCompletion;
    },
    setFieldAnswers: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.fieldAnswers = action.payload;
    }
  }
});

export const { putAnswer, resetCompletion, setFieldAnswers } =
  completionSlice.actions;
export default completionSlice.reducer;
