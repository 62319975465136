import api from './api';
import store from '../store';
import db from './db';
import { TermsAndConditionsElement } from '../types/utils';
import { certificationsQueryFn, defaultQueryFn } from '../utils/queries';
import voroQueryClient from './voroQueryClient';
import { setDebugMode, setUpdateStatus } from '../slices/sessionSlice';
import { CoworkerListRes } from '../../vorotypes/types/routes.app.responses';
import { setAdvancedCamera } from '../slices/configSlice';
import formFilloutClient from './formFillout/client';

export function updateRedux() {
  try {
    // Update Values (DexieDB -> redux_store)
    formFilloutClient.persistor.restore();
    // Update Values (DexieDB -> redux_store)
    db.table('misc')
      .where('key')
      .equals('debugMode')
      .toArray()
      .then(dbres => {
        if (!!dbres[0]?.value) store.dispatch(setDebugMode(true));
        else store.dispatch(setDebugMode(false));
      });
    db.table('misc')
      .where('key')
      .equals('updateStatus')
      .toArray()
      .then(dbres => {
        if (!!dbres[0]?.value) store.dispatch(setUpdateStatus(dbres[0].value));
        else store.dispatch(setUpdateStatus('sleeping'));
      });
    db.table('misc')
      .where('key')
      .equals('advancedCamera')
      .toArray()
      .then(dbres => {
        if (!!dbres[0]?.value) store.dispatch(setAdvancedCamera(true));
        else store.dispatch(setAdvancedCamera(false));
      });
  } catch (err) {
    console.error(err);
  }
}

/**
 * @description Caches form answer's redux's current state
 */
export function updateDexieFormAnswer() {
  formFilloutClient.persistor.save();
}

/**
 * @description Fetch data that must be available offline
 */
export function fetchAPI() {
  try {
    if (!navigator.onLine) throw new Error('Network Error: offline');
    console.log('Fetching API');
    /* Prefetching queries */
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['companyServices'],
        route: '/app/companyServices',
        queryFn: defaultQueryFn
      });
    }, 1000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['certifications'],
        route: '/app/certifications',
        queryFn: certificationsQueryFn
      });
    }, 2000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery<CoworkerListRes>({
        queryKey: ['coworkers'],
        route: '/app/coworkersList',
        queryFn: defaultQueryFn
      });
    }, 3000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['termsAndConditions'],
        route: '/app/termsAndConditions',
        queryFn: async () =>
          (
            await api.get<{ elements: TermsAndConditionsElement[] }>(
              '/termsAndConditions'
            )
          ).data.elements
      });
    }, 4000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['userCertifications'],
        route: '/app/activeUserCertifications',
        queryFn: defaultQueryFn
      });
    }, 5000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['documentsHistory'],
        route: '/app/documentsHistory',
        queryFn: defaultQueryFn
      });
    }, 6000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['businessUnits'],
        route: '/app/businessUnits',
        queryFn: defaultQueryFn
      });
    }, 7000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['voroRoles'],
        route: '/app/voroRoles',
        queryFn: defaultQueryFn
      });
    }, 8000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['dqsTopics'],
        route: '/app/dqsTopics',
        queryFn: defaultQueryFn
      });
    }, 9000);
  } catch (err) {
    console.log(err);
  }
}

export async function clearDB() {
  try {
    await db.table('cache').clear();
    await db.table('answers').clear();
    await db.table('misc').clear();
    await db.table('cancellations').clear();
    await db.table('edits').clear();
    await db.queryCache.clear();
    await db.mutationCache.clear();
    await db.s3Cache.clear();
    await db.filloutCache.clear();
  } catch (error) {
    console.error(error);
  }
}
