import store from '../../../store';
import { setRoadBlockers } from '../slice';
import { RoadBlocker } from './roadBlocker.types';

export class FormFilloutRoadBlocker {
  constructor() {}
  /**
   * Deletes a roadBlocker from formFillout's slice
   * @param id the string id of a roadBlocker
   * @returns `0` if there is no roadBlocker with the informed id
   * @returns `1` if the roadBlocker was removed successfully
   * @author Douglas Flores
   */
  delete(id: string): 0 | 1 {
    const currentRoadblockers = store.getState().fillout.roadBlockers;
    if (!currentRoadblockers) return 0;
    const filtered = currentRoadblockers?.filter(rb => rb.field_id !== id);
    store.dispatch(setRoadBlockers(filtered));
    return 1;
  }
  /**
   * Gets a roadBlocker by the id
   * @param id the string id of a roadBlocker
   * @returns `undefined` if the roadBlocker is not found
   * @returns the requested roadBlocker when it is found
   * @author Douglas Flores
   */
  get(id: string): RoadBlocker | undefined {
    const roadBlockers = this.getAll();
    if (!roadBlockers) return;
    return roadBlockers.find(rb => rb.field_id === id);
  }
  /**
   * Gets all roadBlockers
   * @returns the roadBlockers array from formFillout's slice
   * @author Douglas Flores
   */
  getAll(): RoadBlocker[] | undefined {
    return store.getState().fillout.roadBlockers;
  }
  /**
   * Puts a roadBlocker into roadBlockers
   * @description if a roadBlocker with the same id exists, it is replaced, otherwise the roadBlocker is added
   * @param roadblocker the roadblocker to be putted
   * @author Douglas Flores
   */
  put(roadblocker: RoadBlocker): void {
    const currentRoadblockers = store.getState().fillout.roadBlockers;
    if (!currentRoadblockers) {
      store.dispatch(setRoadBlockers([roadblocker]));
      return;
    }
    const roadblockers = currentRoadblockers.filter(
      rb => rb.field_id !== roadblocker.field_id
    );
    roadblockers.push(roadblocker);
    store.dispatch(setRoadBlockers(roadblockers));
  }
  /**
   * Resets the roadBlockers list to `undefined`
   * @author Douglas Flores
   */
  reset(): void {
    store.dispatch(setRoadBlockers());
  }
}
