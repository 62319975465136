import React, { useEffect, useState } from 'react';
import { InputBase, Typography } from '@mui/material';
import ffc from '../../../../services/formFillout/client';
import { FormField } from '../../../../../vorotypes/types/formTemplate';

type FieldNumberProps = {
  field: FormField;
  setAnswers: React.Dispatch<
    React.SetStateAction<{ field_id: string; answer: any }[]>
  >;
};

export default function FieldNumber(props: FieldNumberProps) {
  const { field, setAnswers } = props;
  /* States */
  const [input, setInput] = useState<string>(
    ffc.fieldAnswer.get(field.field_id) || ''
  );
  /* Functions */
  const updateAnswer = () =>
    setAnswers((prev: any) => {
      const payload = { field_id: field?.field_id, answer: input };
      if (!prev) return [payload];
      const prevAnswer = prev?.findIndex(
        (ans: any) => ans.field_id === field.field_id
      );
      if (prevAnswer > -1) {
        prev[prevAnswer] = payload;
        return [...prev];
      } else {
        return [...prev, payload];
      }
    });
  const onInputChange = (evt: any) => {
    const value = evt.target.value;
    if (value?.length < 1) setInput(value);
    const numberVal = Number(value);
    setInput(old => (Number.isNaN(numberVal) ? old : value));
  };
  /* useEffects */
  useEffect(() => {
    updateAnswer();
    //eslint-disable-next-line
  }, [input]);
  /* --- Return --- */
  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h3" marginTop={1}>
        {field?.properties?.question}
      </Typography>
      {field?.properties?.question !== field?.properties?.description && (
        <Typography>{field?.properties?.description}</Typography>
      )}
      <InputBase
        id="txt-number-input-answer"
        fullWidth
        placeholder={field?.properties?.placeholder ?? '0'}
        style={{ fontSize: 20 }}
        value={input}
        onChange={onInputChange}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />
    </div>
  );
}
